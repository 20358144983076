import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Menu from "@mui/material/Menu";
import ViewListIcon from "@mui/icons-material/ViewList";
import GroupIcon from "@mui/icons-material/Group";
import Container from "@mui/material/Container";
import { controllaLogin, monFetch } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HomeIcon from "@mui/icons-material/Home";

const Comp = (props) => {
  //const ruoloLogin = props.controllaLogin();
  //console.log(444, ruoloLogin);
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [level, setLevel] = useState(null);

  let location = useLocation();

  useEffect(() => {
    //redirect se non loggato
    controllaLogin();
    getUtente();
  }, [location]);

  async function getUtente() {
    let res = await monFetch("/userGet", {
      id: Cookies.get("id"),
    });
    //console.log(444, res);
    setEmailUtente(res.user.email);
    setNomeCognome(res.user.first_name + " " + res.user.last_name);
    //setLevel(res.user.level);
  }

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    // Cookies.remove("adminLevel");
    // Cookies.remove("companyId");
    window.location.href = "/admin/login";
  };

  return (
    <>
      <AppBar position="static" sx={{ mb: 2 }} className="barraTop">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                edge="start"
                sx={{ mr: 2 }}
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <MenuIcon />
              </IconButton>

              <a href="/admin" style={{ marginRight: "15px", display: "flex" }}>
                <img
                  src="/img/logo.png"
                  style={{ width: "200px", padding: "10px 0" }}
                />
              </a>
            </div>
            <div
              style={{
                flexGrow: 1,
                padding: "0 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {props.elementiBarraTop}
            </div>
            <div style={{ display: "flex" }}>
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
              <IconButton
                className="bottoneUtente"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".bottoneUtente")}
                id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div>
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
          <audio id="pop-sound" src="/sound/pop.wav" />
        </Container>
      </AppBar>

      <Drawer
        className="menuLaterale"
        anchor="left"
        open={menuAperto}
        onClose={() => setMenuAperto(!menuAperto)}
      >
        <List style={{ width: 200 }}>
          <ListItem disablePadding>
            <ListItemButton href="/admin">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Homepage" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              href="/admin/contatti"
              // onClick={() => setMenuAperto(!menuAperto)}
            >
              <ListItemIcon>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText primary="Contatti" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Container>
        {/* //mostra la route figlia */}
        <Outlet />
      </Container>
      {/* {props.children} */}
      <br />
      <br />
      <br />
    </>
  );
};

export default Comp;
