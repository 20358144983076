import Testo from "../../form/Testo";
import { useState, useEffect } from "react";

export default function Comp(props) {
  useEffect(()=>{
    //redirect a home
    setTimeout(()=>{
      window.location.href="/"
    }, 10000)
  },[])

  return (
    <>
      <h2 className="form_titolo center">
        Il messaggio è stato ricevuto, grazie.
        <br />A presto!
      </h2>
      <img
        src="/img/smile_chiusura.svg"
        style={{ width: 120, display: "block", margin: "auto" }}
      />
      <div className="logo center">
        <img
          src="/img/logo.png"
          style={{
            width: 300,
            display: "block",
            margin: "auto",
            marginTop: 100,
          }}
        />
      </div>
    </>
  );
}
