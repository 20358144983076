import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { convertiData, monFetch } from "../../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";

import Checkbox from "@mui/material/Checkbox";

import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const Comp = (props) => {
  let location = useLocation();
  let params = useParams();

  let id = params.id;

  const [azienda, setAzienda] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [messaggio, setMessaggio] = useState("");
  const [motivo, setMotivo] = useState("");
  const [industria, setIndustria] = useState(false);
  const [ferramenta, setFerramenta] = useState(false);
  const [belleArti, setBelleArti] = useState(false);
  const [pittura, setPittura] = useState(false);

  async function query() {
    const res = await monFetch("/contactGet", { id });
    let res2 = res.res;
    setAzienda(res2.company || "");
    setNome(res2.first_name || "");
    setCognome(res2.last_name || "");
    setEmail(res2.email || "");
    setTelefono(res2.mobile || "");
    setData(res2.timestamp.split(" ")[0] || "");
    setMessaggio(res2.message || "");
    setMotivo(res2.visitReason || "");
    setIndustria(res2.industry ? true : false);
    setBelleArti(res2.fine_arts ? true : false);
    setFerramenta(res2.ironmongery ? true : false);
    setPittura(res2.paint ? true : false);
  }

  async function modificaContatto() {
    const res = await monFetch("/contactEdit", {
      id: id,
      company: azienda,
      first_name: nome,
      last_name: cognome,
      mobile: telefono,
      industry: industria ? 1 : 0,
      fine_arts: belleArti ? 1 : 0,
      ironmongery: ferramenta ? 1 : 0,
      paint: pittura ? 1 : 0,
    });

    window.location.href = "/admin/contatti"
  }

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <h2>Modifica dati contatto</h2>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Email"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={email}
            disabled
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Azienda"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={azienda}
            onChange={(e) => {
              setAzienda(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Cognome"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={cognome}
            onChange={(e) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Telefono"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={telefono}
            onChange={(e) => {
              setTelefono(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            label="Data"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            disabled
            variant="outlined"
            className="inputCustom"
            value={data}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Messaggio"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={messaggio}
            disabled
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Motivo Visita"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            variant="outlined"
            className="inputCustom"
            value={motivo}
            disabled
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex" }}>
            <div className="labelCheck">
              <Checkbox
                checked={industria}
                onChange={(e) => {
                  setIndustria(e.target.checked);
                }}
              />
              Industria
            </div>
            <div className="labelCheck">
              <Checkbox
                checked={ferramenta}
                onChange={(e) => {
                  setFerramenta(e.target.checked);
                }}
              />
              Ferramenta
            </div>
            <div className="labelCheck">
              <Checkbox
                checked={belleArti}
                onChange={(e) => {
                  setBelleArti(e.target.checked);
                }}
              />
              Belle Arti
            </div>
            <div className="labelCheck">
              <Checkbox
                checked={pittura}
                onChange={(e) => {
                  setPittura(e.target.checked);
                }}
              />
              Pittura
            </div>
          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button
            variant="contained"
            onClick={(e) => {
              modificaContatto();
            }}
          >
            Salva
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Comp;
