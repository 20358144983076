import Testo from "../../form/Testo";
import TextArea from "../../form/TextArea";
import { useParams } from "react-router-dom";
import SwitchPrivacy from "../../form/SwitchPrivacy";
import { useState, useEffect } from "react";
import { monFetch } from "../../inc/inc";

import Grid from "@mui/material/Grid";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { InputBase, TextField } from "@mui/material";

export default function Comp(props) {
  const params = useParams();
  const idVoto = params.id;

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [azienda, setAzienda] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [messaggio, setMessaggio] = useState("");

  const [ferramenta, setFerramenta] = useState(false);
  const [belleArti, setBelleArti] = useState(false);
  const [industria, setIndustria] = useState(false);
  const [idropittura, setIdropittura] = useState(false);

  const [privacy, setPrivacy] = useState(false);

  const [motivazione, setMotivazione] = useState("");
  const [motivazioni, setMotivazioni] = useState([]);

  const [msgErrore, setMsgErrore] = useState({
    msg: null,
    campi: [],
    campiRequired: [],
  });

  // const [timeoutC, setTimeoutC] = useState(null);

  let classeBottone = "button_ok";

  const inviaForm = async function (e) {
    e.preventDefault();
    // console.log(motivazione)
    // return

    const res = await monFetch("/contactAdd", {
      first_name: nome,
      last_name: cognome,
      company: azienda,
      privacy: privacy,
      mobile: telefono,
      email: email,
      rating_id: idVoto,
      message: messaggio,
      industry: industria,
      fine_arts: belleArti,
      ironmongery: ferramenta,
      paint: idropittura,
      visit_reason: motivazione
    });
    if (res.success) {
      window.location.href = "/thank-you";
    } else {
      //genero messaggio errore
      let msg = { ...msgErrore };
      // if (res.fields) {
      //   msg += "Mancano alcuni dati richiesti.";
      //   res.fields.forEach((el) => {
      //     if (el == "first_name") {
      //       msg += "<b>Nome</b> ";
      //     }
      //     if (el == "last_name") {
      //       msg += "<b>Cognome</b> ";
      //     }
      //   });
      // }
      if (res.inOr) {
        msg.msg = "Inserire almeno uno dei dati evidenziati";
        res.inOr.forEach((el) => {
          //popolo array di campi con errore
          msg.campi.push(el);
        });
      }

      setMsgErrore(msg);
    }
  };

  const queryMotivazioni = async () => {
    const res = await monFetch("/contactListVisitReason", {});
    setMotivazioni(res.reason);
  };

  const controllaCampiRequired = () => {
    let msg = { ...msgErrore };
    msg.campiRequired = [];
    if (!nome) {
      msg.campiRequired.push("nome");
    }
    if (!cognome) {
      msg.campiRequired.push("cognome");
    }
    console.log(msg);

    setMsgErrore(msg);
  };

  useEffect(() => {
    let timeout2;
    //redirect a home dopo inattività
    function attivaTimer() {
      // console.log("set " + timeout2);
      timeout2 = setTimeout(() => {
        window.location.href = "/";
      }, 60000);
    }

    attivaTimer();

    //cancella timer al movimento del mouse

    function clearTimer() {
      // console.log("clear " + timeout2);
      clearTimeout(timeout2);
      attivaTimer();
    }
    document.addEventListener("mousemove", clearTimer);
    document.addEventListener("keyup", clearTimer);
    document.addEventListener("ontouchstart", clearTimer);

    queryMotivazioni();
  }, []);

  return (
    <>
      <a href="/" className="indietro">
        <img src="/img/previous.png" style={{ width: 30 }} />
      </a>
      <form
        onSubmit={(e) => {
          inviaForm(e);
        }}
      >
        <h2>{props.titolo}</h2>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Testo
              className={
                msgErrore.campiRequired.includes("nome") ? "error" : ""
              }
              value={nome}
              setValue={setNome}
              placeholder="Nome*"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <Testo
              className={
                msgErrore.campiRequired.includes("cognome") ? "error" : ""
              }
              required
              value={cognome}
              setValue={setCognome}
              placeholder="Cognome*"
            />
          </Grid>
          <Grid item xs={4}>
            <Testo
              className={
                msgErrore.campiRequired.includes("azienda") ? "error" : ""
              }
              value={azienda}
              setValue={setAzienda}
              placeholder="Azienda"
            />
          </Grid>
          <Grid item xs={6}>
            <Testo
              className={msgErrore.campi.includes("mobile") ? "warning" : ""}
              value={telefono}
              setValue={setTelefono}
              placeholder="Telefono"
            />
          </Grid>
          <Grid item xs={6}>
            <Testo
              className={msgErrore.campi.includes("email") ? "warning" : ""}
              value={email}
              setValue={setEmail}
              placeholder="Mail"
              type="email"
            />
          </Grid>

          <Grid item xs={12}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <NativeSelect
              className="selectCustom"
              value={motivazione}
              // label="Age"
              onChange={(e) => setMotivazione(e.target.value)}
            >
              <option value={""}>Come hai conosciuto il Colorificio Torreggiani? (Seleziona)</option>
              {motivazioni.map((el)=>{
                return <option value={el.id} key={el.id}>{el.reason}</option>
              })}
              
            </NativeSelect>
          </Grid>

          <Grid item xs={12}>
            <TextArea
              value={messaggio}
              setValue={setMessaggio}
              placeholder="Lasciaci un messaggio (opzionale)"
            />
          </Grid>
          <Grid item xs={12}>
            <div className="form_description center testoGrande">
              Seleziona a quali newsletter vuoi iscriverti.
            </div>
            <div className="center">
              <SwitchPrivacy
                className="switch_privacy ferr "
                privacy={ferramenta}
                setPrivacy={setFerramenta}
              />
              <span className="labelswitch  testoGrande">FERRAMENTA</span>
              <SwitchPrivacy
                className="switch_privacy arti"
                privacy={belleArti}
                setPrivacy={setBelleArti}
              />
              <span className="labelswitch  testoGrande">BELLE ARTI</span>
              <SwitchPrivacy
                className="switch_privacy ind"
                privacy={industria}
                setPrivacy={setIndustria}
              />
              <span className="labelswitch  testoGrande">INDUSTRIA</span>
              <br />
              <SwitchPrivacy
                className="switch_privacy idrop"
                privacy={idropittura}
                setPrivacy={setIdropittura}
              />
              <span className="labelswitch  testoGrande">
                IDROPITTURA E DECORATIVI
              </span>
            </div>
          </Grid>
          {/* messaggio errore */}
          {msgErrore.msg && (
            <Grid item xs={12}>
              <div
                className="msgErrore"
                dangerouslySetInnerHTML={{ __html: msgErrore.msg }}
              ></div>
            </Grid>
          )}

          <Grid item xs={12}>
            <p className="form_description">
              I campi contrassegnati con (*) sono obbligatori. Tutte le
              comunicazioni saranno trattate nel pieno rispetto della Privacy e
              della normativa vigente ai sensi del Reg.to UE 2016/679 (GDPR).
            </p>
          </Grid>
        </Grid>

        <div className="flex space_between align_center">
          <div className="flex">
            <SwitchPrivacy
              className="switch_privacy ruotato"
              privacy={privacy}
              setPrivacy={setPrivacy}
            />

            <span className="form_description">
              Dichiaro di aver letto e accettato l'<a href="/pdf/informativa-privacy.pdf" target="_blank" style={{color: "#b0daff"}}>informativa sulla Privacy</a> ex
              artt. 13-14 Reg.to UE 2016/679 e autorizzo il trattamento dei dati
              personali ai sensi della normativa vigente.
            </span>
          </div>
          <div>
            <button
              className={"button " + classeBottone}
              disabled={!privacy}
              onClick={() => {
                controllaCampiRequired();
              }}
            >
              Invia
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
