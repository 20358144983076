import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Container from "@mui/material/Container";

const Comp = (props) => {
  let location = useLocation();

  //   useEffect(() => {
  //     //   //in file inc. redirect se non in pagina libri
  //     controllaLogin();
  //     getUtente();
  //   }, [location]);

  //   const logout = () => {
  //     Cookies.remove("token");
  //     Cookies.remove("id");
  //     Cookies.remove("adminLevel");
  //     Cookies.remove("companyId");
  //     window.location.href = "/login";
  //   };

  return (
    <div id="rootTablet">
      <Container className="container">
        <div className="centratoVert">
          <div style={{ width: " 100%" }}>
            <Outlet />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Comp;
