import FormSoddisfatto from "./FormSoddisfatto";
import { useState } from "react";
export default function Comp(props) {
  return (
    <>
      <h2 className="form_titolo center">
        Grazie per la tua opinione, lasciaci una
        mail per restare in contatto!
      </h2>
      <FormSoddisfatto soddisfatto={false}/>
    </>
  );
}
