import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { convertiData, monFetch } from "../../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";

const Comp = (props) => {
  let location = useLocation();

  const [contatti, setContatti] = useState([]);

  const [dataInizio, setDataInizio] = useState(null);
  const [dataFine, setDataFine] = useState(null);
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [voto, setVoto] = useState("");
  const [industria, setIndustria] = useState("");
  const [belleArti, setBelleArti] = useState("");
  const [ferramenta, setFerramenta] = useState("");
  const [idroPitt, setIdroPitt] = useState("");
  const [mexal, setMexal] = useState("");

  const [totale, setTotale] = useState(0);

  const [options, setOptions] = useState([
    { label: "Tutti", value: "" },
    { label: "Si", value: 1 },
    { label: "No", value: 0 },
  ]);

  const [optionsVoto, setOptionsVoto] = useState([
    { label: "Tutti", value: "" },
    { label: "Positivo", value: 1 },
    { label: "Negativo", value: 0 },
  ]);

  const [numeroPagine, setNumeroPagine] = useState(1);
  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(50);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  async function query(data = {}) {
    const res = await monFetch("/contactList", data);
    setContatti(res.contacts);
    setNumeroPagine(Math.ceil(res.tot / risPerPagina));
    setTotale(res.tot);
  }

  useEffect(() => {
    query({
      dateFrom: dataInizio,
      dateTo: dataFine,
      mobileRequired: telefono,
      emailRequired: email,
      grade: voto,
      industryRequired: industria,
      fineArtsRequired: belleArti,
      ironmongeryRequired: ferramenta,
      paintRequired: idroPitt,
      mexalRequired: mexal,
      pagina: pagina,
      rec_for_page: risPerPagina,
    });
  }, [
    dataInizio,
    dataFine,
    telefono,
    email,
    voto,
    ferramenta,
    belleArti,
    industria,
    pagina,
    idroPitt,
    mexal
  ]);

  async function eliminaContatto(id) {
    const res = await monFetch("/contactDelete", { id: id });
    if (res.success) {
      query();
    }
  }

  return (
    <>
      <br />
      <br />
      <Grid container spacing={1}>
        <Grid item sm={1} xs={12}>
          <TextField
            label="Data Inizio"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataInizio || ""}
            onChange={(e) => {
              setDataInizio(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <TextField
            label="Data Fine"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            variant="outlined"
            className="inputCustom"
            value={dataFine || ""}
            onChange={(e) => {
              setDataFine(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={1} xs={12}>
          {/* //cell */}
          <Autocomplete
            value={options.find((op) => op.value === telefono)}
            onChange={(e, value) => {
              if (!value) {
                setTelefono("");
                return;
              }
              setTelefono(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Telefono"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          {/* //email */}
          <Autocomplete
            value={options.find((op) => op.value === email)}
            onChange={(e, value) => {
              if (!value) {
                setEmail("");
                return;
              }
              setEmail(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //voto */}
          <Autocomplete
            value={optionsVoto.find((op) => op.value === voto)}
            onChange={(e, value) => {
              if (!value) {
                setVoto("");
                return;
              }
              setVoto(value.value);
            }}
            options={optionsVoto}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Voto"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //Industria */}
          <Autocomplete
            value={options.find((op) => op.value === industria)}
            onChange={(e, value) => {
              if (!value) {
                setIndustria("");
                return;
              }
              setIndustria(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Industria"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //ferramenta */}
          <Autocomplete
            value={options.find((op) => op.value === ferramenta)}
            onChange={(e, value) => {
              if (!value) {
                setFerramenta("");
                return;
              }
              setFerramenta(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ferramenta"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //belle arti */}
          <Autocomplete
            value={options.find((op) => op.value === belleArti)}
            onChange={(e, value) => {
              if (!value) {
                setBelleArti("");
                return;
              }
              setBelleArti(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Belle arti"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //idropittura */}
          <Autocomplete
            value={options.find((op) => op.value === idroPitt)}
            onChange={(e, value) => {
              if (!value) {
                setIdroPitt("");
                return;
              }
              setIdroPitt(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Idropitt."
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          {/* //mexal */}
          <Autocomplete
            value={options.find((op) => op.value === mexal)}
            onChange={(e, value) => {
              if (!value) {
                setMexal("");
                return;
              }
              setMexal(value.value);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mexal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="risultati">Risultati: {totale}</div>
        </Grid>
      </Grid>

      <Table id="tabellaAttivita">
        <TableHead>
          <TableRow>
            <TableCell>Voto</TableCell>
            <TableCell>Azienda</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Cognome</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>Messaggio</TableCell>
            <TableCell>Motivo Visita</TableCell>
            <TableCell>Industria</TableCell>
            <TableCell>Ferramenta</TableCell>
            <TableCell>Belle Arti</TableCell>
            <TableCell>
              Idropitt.<br />
              Decor.
            </TableCell>
            <TableCell>Sync</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contatti.map((t) => {
            return (
              <TableRow key={t.id}>
                <TableCell>
                  {t.grade === 1 &&
                    <DoneIcon className="iconSuccess" />}
                 {t.grade === 0 &&
                    <CloseIcon className="iconError" />
                 }
                </TableCell>
                <TableCell>{t.company}</TableCell>
                <TableCell>{t.first_name}</TableCell>
                <TableCell>{t.last_name}</TableCell>
                <TableCell>{convertiData(t.timestamp)}</TableCell>
                <TableCell>{t.email}</TableCell>
                <TableCell>{t.mobile}</TableCell>
                <TableCell>{t.message}</TableCell>
                <TableCell>{t.visitReason}</TableCell>
                <TableCell>
                  {t.industry == 1 ? <DoneIcon className="iconSuccess" /> : ""}
                </TableCell>
                <TableCell>
                  {t.ironmongery == 1 ? (
                    <DoneIcon className="iconSuccess" />
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell>
                  {t.fine_arts == 1 ? <DoneIcon className="iconSuccess" /> : ""}
                </TableCell>
                <TableCell>
                  {t.paint == 1 ? <DoneIcon className="iconSuccess" /> : ""}
                </TableCell>
                <TableCell>
                  <EmailIcon style={{color : t.mailchimp_sync? "#3DCD70" : "#ccc"}}/>
                  <GoogleIcon style={{color : t.google_sync? "#3DCD70" : "#ccc"}}/>
                </TableCell>
                <TableCell style={{ minWidth: 180 }}>
                  <Button
                    variant="contained"
                    href={"/admin/contatto-modifica/" + t.id}
                    style={{ margin: "2px 3px" }}
                  >
                    <RemoveRedEyeIcon />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      setPopupElimina(true);
                      setDaEliminare(t.id);
                    }}
                    color="error"
                    style={{ margin: "2px 3px" }}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <Pagination
        count={numeroPagine}
        page={pagina}
        onChange={(e, v) => setPagina(v)}
      />
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => {
          eliminaContatto(daEliminare);
          setPopupElimina(false);
        }}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
