import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import CssBaseline from "@mui/material/CssBaseline";

import Login from "./comp/admin/Login";
import Home from "./comp/tablet/Home";
import Layout from "./comp/tablet/Layout";
import Soddisfatto from "./comp/tablet/Soddisfatto";
import NonSoddisfatto from "./comp/tablet/NonSoddisfatto";
import Messaggioinviato from "./comp/tablet/MessaggioInviato";

import LayoutAdmin from "./comp/admin/LayoutAdmin";
import HomeAdmin from "./comp/admin/HomeAdmin";
import ContattiLista from "./comp/admin/ContattiLista";
import ContattiModifica from "./comp/admin/ContattiModifica";
import RecuperaPassword from "./comp/admin/RecuperaPassword";
import ReimpostaPassword from "./comp/admin/ReimpostaPassword";

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/recupera-password" element={<RecuperaPassword />} />
        <Route path="/admin/reimposta-password" element={<ReimpostaPassword />} />

        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/soddisfatto/:id" element={<Soddisfatto />} />
          <Route path="/non-soddisfatto/:id" element={<NonSoddisfatto />} />
          <Route path="/thank-you" element={<Messaggioinviato />} />
        </Route>

        <Route path="/admin" element={<LayoutAdmin />}>
          <Route path="/admin" element={<HomeAdmin />} />
          <Route path="/admin/contatti" element={<ContattiLista />} />
          <Route path="/admin/contatto-modifica/:id" element={<ContattiModifica />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
