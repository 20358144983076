import { convertiData, monFetch, checkRuoloUtente } from "../../inc/inc";

import Chart from "react-apexcharts";

import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

const Comp = (props) => {
  let location = useLocation();

  const [positivi, setPositivi] = useState(0);
  const [negativi, setNegativi] = useState(0);

  const [dataInizio, setDataInizio] = useState(null);
  const [dataFine, setDataFine] = useState(null);

  const [googleSync, setGoogleSync] = useState(false)
  const [mailSync, setMailSync] = useState(false)

  async function query(data) {
    const res = await monFetch("/ratingList", data);
    if (res.success) {
      setPositivi(res.pos);
      setNegativi(res.neg);
    }
  }

  async function syncMailchimp(data) {
    setMailSync(true)
    const res = await monFetch("/syncContacts");
    console.log(res)
    if (res.success) {
      window.location.href = "/admin/contatti";
    }
  }

  async function syncGoogle() {
    let url =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      "?client_id="+process.env.REACT_APP_GOOGLE_CLIENT_ID +
      "&redirect_uri=https://soddisfazione.coloribricotorreggiani.it/admin" +
      "&response_type=token" +
      "&scope="+process.env.REACT_APP_GOOGLE_SCOPE;
    //al termine reindirizza al redirect_uri sopra
    // console.log(url)
    // return
    window.location.href=url;

    // const res = await fetch(url, {
    //   method: "GET",
    //   // headers: {
    //   //   "Content-Type": "application/json",
    //   //   monToken: Cookies.get("token"),
    //   //   monId: Cookies.get("id"),
    //   //   monComId: Cookies.get("companyId"),
    //   // },
    //   // body: JSON.stringify(data),
    // });
    // console.log(res)
  }

  useEffect(() => {
    async function fn() {
      /////se access token dopo redirect
      let token = new URLSearchParams(window.location.hash).get(
        "#access_token"
      );
      console.log(token)
      if (token) {
        setGoogleSync(true)
        const res = await monFetch("/syncGoogle", { token });
        if (res.success) {
          window.location.href = "/admin";
        }

      }
    }
    fn();
  }, []);
  useEffect(() => {
    query({
      dateFrom: dataInizio,
      dateTo: dataFine,
    });
  }, [dataInizio, dataFine]);

  return (
    <>
      <h2>Riepilogo Valutazioni</h2>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            type="date"
            size="small"
            fullWidth
            label="Data Inizio"
            value={dataInizio || ""}
            onChange={(e) => setDataInizio(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="date"
            size="small"
            fullWidth
            label="Data Fine"
            value={dataFine || ""}
            onChange={(e) => setDataFine(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            fullWidth
            onClick={(e) => syncMailchimp()}
            disabled={mailSync}
          >
            Sync Mailchimp
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" fullWidth 
          disabled={googleSync}
          onClick={(e) => syncGoogle()}>
            Sync Google Contacts
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <div
            className="bloccoHomeAdmin"
            onClick={() => {
              window.location.href = "/admin/contatti";
            }}
            style={{ cursor: "pointer" }}
          >
            Totale: <b>{positivi + negativi}</b>
          </div>
          <div className="bloccoHomeAdmin">
            Positive: <b className="testoSuccess">{positivi}</b>
          </div>
          <div className="bloccoHomeAdmin">
            Negative: <b className="testoError">{negativi}</b>
          </div>
        </Grid>
        <Grid item xs={5}>
          <Chart
            options={{
              labels: ["Positive", "Negative"],
              colors: ["#3DCD70", "#F14F4D"],
            }}
            series={[positivi, negativi]}
            type="pie"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Comp;
