import Cookies from "js-cookie";

const monFetch = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
      monComId: Cookies.get("companyId"),
    },
    body: JSON.stringify(data),
  });

  let res = await response.json();
  if (res.monCheck == false) {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("roles");
    window.location.href = "/admin/login";
  }

  return res;
};

const monForm = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
    },
    body: data,
  });

  let res = await response.json();

  return res;
};

const controllaLogin = () => {
  console.log(window.location);
  if (!Cookies.get("token")) {
    window.location.href = "/admin/login";
  }
};

function convertiData(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export { monFetch, monForm, controllaLogin, convertiData };
