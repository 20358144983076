import Testo from "../../form/Testo";
import { useState } from "react";
import { monFetch } from "../../inc/inc";
import Grid from "@mui/material/Grid";

const BloccoHome = function (props) {
  const salvaVoto = async function (valore) {
    const res = await monFetch("/ratingAdd", { grade: props.voto });
    if(res.success){
      window.location.href= props.url+"/"+res.rating_id
    }
  };

  return (
    <div
      className="bloccoHome center"
      style={{cursor: 'pointer'}}
      onClick={() => {
        salvaVoto(props.voto);
      }}
    >
      <img src={props.immagine} />
      <p className="center">{props.testo}</p>

      <button className={"button " + props.classeBottone}>
        {props.testoBottone}
      </button>
    </div>
  );
};

export default function Comp(props) {
  return (
    <>
      <h2 className="form_titolo center">
        Come ti sei trovato al Colorificio Torreggiani?
        <br />
        Il tuo parere da noi, conta!
      </h2>
      <div className="flex">
        <BloccoHome
          url="/soddisfatto"
          voto={1}
          immagine="/img/smile_verde_centrato.png"
          testo="Il servizio è stato soddisfacente, ci rivedremo!"
          testoBottone="Bene!"
          classeBottone="button_ok"
        />
        <BloccoHome
          url="/non-soddisfatto"
          voto={0}
          immagine="/img/smile_rosso_centrato.png"
          testo="Hmm, probabilmente qualcosa è andato storto."
          testoBottone="Oops!"
          classeBottone="button_error"
        />
      </div>

      {/* <a onClick={()=>{
        document.querySelector("#root").requestFullscreen();
      }}>FULLSCREEN</a> */}
    </>
  );
}
